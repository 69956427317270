/* src/ServiceProviderLinks.css */
.container {
    text-align: center;
    justify-content: center;
    align-items: center;
    color: #888;
    margin-top: 10%;
  }
  
  .red-hr {
    border: 0;
    height: 2px;
    background: red;
    margin-bottom: 20px;
  }
  
  .grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .grid-item {
    width: 30%;
    margin: 10px;
    padding: 20px;
    border: 1px solid #ddd;
    text-decoration: none;
    color: inherit;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s;
    transition: background-color 1s ease;
    border-radius: 5px;
    box-sizing: border-box;
  }
  
  .grid-item:hover {
    transform: scale(1.05);
    border: 1px solid red;
    border-radius: 5px;
  }
  
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .content p {
    align-self: flex-start;
    margin: 0 0 10px;
  }
  
  .content img {
    max-width: 100%;
    max-height: 150px;
    object-fit: contain;
  }
  
  .container h1 {
    text-align: left; /* Align the h1 to the left */
    margin-left: 20px; /* Add margin for better spacing */
  }
  
  .grid-item .content p {
    white-space: normal; /* Allows text to wrap */
    overflow-wrap: break-word; /* Ensures long words do not overflow */
  }
  
  /* Media query for screens less than 657px wide */
  @media (max-width: 567px) {
    .container {
      padding-top: 260px;
      margin-top: 420px;
    }
    .grid-item:first-child {
      margin-top: 50px; /* Adds top margin to the first grid item */
    }
    .grid-item {
      width: 100%; /* Makes grid items take full width of the container */
      padding: 15px; /* Adjust padding to ensure content has enough space */
    }
  
    .grid-item .content p {
      font-size: 14px; /* Optional: Adjust font size for smaller screens */
    }
  }
@import url(https://db.onlinewebfonts.com/c/84308244b9f99a3c9f3d5c44f74aad6a?family=Chalet-ParisNineteenSixty);

body {
  margin: 0;
  background: white;
  display: flex;
  font-family: "Chalet-ParisNineteenSixty";
  color: #606062;
  height: 100vh;
  width: 100vw;
  justify-content: center;
  align-items: center;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  max-width: 1280px;
  margin: 0 auto;
  padding: 2rem;
  text-align: center;
}

.logo {
  height: 6em;
  padding-inline: 1.5em;
  will-change: filter;
  transition: filter 300ms;
}
.logo:hover {
  filter: drop-shadow(0 0 2em #646cffaa);
}
.logo.react:hover {
  filter: drop-shadow(0 0 2em #61dafbaa);
}

@keyframes logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (prefers-reduced-motion: no-preference) {
  a:nth-of-type(2) .logo {
    animation: logo-spin infinite 20s linear;
  }
}

.card {
  padding: 2em;
}

.read-the-docs {
  color: #888;
}

.signin-form {
  width:90%;
}

.centered-container {
  display: flex;
  margin: auto;
  margin-top: 10vh;
  flex-direction: column; 
  justify-content: center;
  align-items: center;
  height: 62vh; /* Full viewport height */
  width: 25vw;
  border: lightgrey 2px groove;
  border-radius: 15px;
}
@media (max-width: 800px) {
  .centered-container {
    width: 50vw;
  }
}
@media (max-width: 500px) {
  .centered-container {
    width: 70vw;
  }
}

.signin-label {
  text-align: left;
  margin-bottom: 10px;
  padding-bottom: 10px;
  font-size: 1.1rem;
  color: #888;
}
.signin-input {
  width: 100%; /* Ensure the input takes up the full width */
  box-sizing: border-box; /* Include padding and border in the element's total width */
  padding: 8px; /* Optional: Add padding for better appearance */
  margin-bottom: 10px;
  margin-top: 10px;
  text-align: center;
  font-size: 1.1rem;
  border-radius: 5px;
  color: #888;
}

.signin-input::placeholder {
  text-align: center; /* Center-align the placeholder text */
  font-size: 1.1rem;
  color: #888;
}

.red-button {
  width : 100%;
  background-color: red;
  color: white; /* Optional: Set text color to white for better contrast */
  border: none; /* Optional: Remove border */
  padding: 10px 20px; /* Optional: Add padding for better appearance */
  border-radius: 5px; /* Optional: Add border radius for rounded corners */
  cursor: pointer; /* Optional: Change cursor to pointer on hover */
  font-size: 1.1rem;
  margin-bottom: 10px;
}

.red-button:hover {
  background-color: darkred; /* Optional: Darken the button color on hover */
}

.app-header {
  position: fixed;
  top: 0px;
  left: 10px;
  right: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1000; /* Ensure the header is above other content */
}

.logo {
  max-width: 100%;
  width: 300px;
  height: auto;
}

.signout-button {
  background-color: #ff0000;
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  margin-inline: 1.5em;
  cursor: pointer;
  border-radius: 5px;
}

.signout-button:hover {
  background-color: #cc0000;
}

.headingBorder {
  position: relative;
  display: inline-block;
  width: 90%;
  font-size: 2em;
  font-weight:100;
  padding-bottom: 20px;
  color: #888;
}

.headingBorder::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0%; /* Adjust the space on the left */
  right: 0%; /* Adjust the space on the right */
  height: 1px; /* Border thickness */
  background-color: red; /* Border color */
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3); /* Bottom shadow */
}


@media (max-width: 656px) {
  .app-header {
    display: flex;
    justify-content: space-between;
    align-items: center; /* Align items on the same baseline */
    top: 0;
    width: 100%;
    background-color: #fff;
    z-index: 1000;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .logo {
    height: 40px;
    width: auto;
    margin-bottom: 0; /* Remove bottom margin to align with button */
  }

  .signout-button {
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
  }

  .main-content-background {
    padding-top: 260px;
  }
}
